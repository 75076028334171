class SplitHero {
  constructor(block) {
      this.block = block;

      this.container = this.block.querySelector('.split-hero__container');
      this.left = this.block.querySelector('.split-hero__column--left');
      this.right = this.block.querySelector('.split-hero__column--right');

      this.initEventListeners();
  }

  initEventListeners() {
      this.left.addEventListener('mouseenter', () => this.container.classList.add('hover-left'));
      this.left.addEventListener('mouseleave', () => this.container.classList.remove('hover-left'));

      this.right.addEventListener('mouseenter', () => this.container.classList.add('hover-right'));
      this.right.addEventListener('mouseleave', () => this.container.classList.remove('hover-right'));
  }
}

export default SplitHero;